<script lang="ts">
    import { push, link } from 'svelte-spa-router';
</script>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="/#">
            <img src="pagordlogo.png" alt="" width="150" height="30" />
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon" />
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a
                        use:link
                        class="nav-link active"
                        aria-current="page"
                        href="/">INICIO</a
                    >
                </li>
                <li class="nav-item">
                    <a use:link class="nav-link" href="/createdeposit"
                        >DEPOSITAR</a
                    >
                </li>
            </ul>
            <div class="d-flex">
                <button class="btn btn-outline-primary">INICIAR SECCION</button>
                <button class="btn btn-outline-info">REGISTRARSE</button>
            </div>
        </div>
    </div>
</nav>
