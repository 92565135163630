<script lang="ts">
    async function getCurrency() {
        const res = await fetch(
            'https://partner.tst.auws.cloud/v1/crypto/available-currencies',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'X-AU-Key': '6aaf5521-c190-4f6f-b70f-66bffcff3fc8',
                    'X-AU-Token':
                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZhYWY1NTIxLWMxOTAtNGY2Zi1iNzBmLTY2YmZmY2ZmM2ZjOCJ9.eyJrZXkiOnsia2V5SWQiOiI2YWFmNTUyMS1jMTkwLTRmNmYtYjcwZi02NmJmZmNmZjNmYzgiLCJwYXJ0bmVySWQiOiJjZTA4NmQwYy01NzdkLTRmODItOWMzMS1mZTI1ZWQ0ZTBkNjEiLCJjb250YWN0SWQiOiJmOTVjY2U5NC1mNzM3LTRjNTMtOTI0OS03MmU4MWVlNjc3ZWMifSwic2NvcGVzIjpbImFkbWluIiwidXNlciJdLCJpYXQiOjE2MzE1MzUzMDcsImV4cCI6MTY2MzA3MTMwNywiYXVkIjoicGFydG5lcnMiLCJpc3MiOiJBVSIsImp0aSI6IjI5N2YyM2JmLTg0NWQtNDkyMS05OTAxLTFmNTU0ZGU3ZGVjMSJ9.NGHjGp_SZJfHwaJNS1eOjJweIHSfmFliw593dQyHiaY',
                },
            },
        );
        const text = await res.text();

        console.log('texxx', text);

        if (res.ok) {
            return text;
        } else {
            throw new Error(text);
        }
    }

    let promise = getCurrency();

    function handleClick() {
        promise = getCurrency();
    }
</script>

<button on:click={handleClick}> Get Currency </button>

<!-- <p>{promise}</p> -->

<!-- // <div class="container">
     <form class="mt-3">
//         <h2>TRANSACCIONES DEPOSITOS</h2>
//         <hr />
//         <div class="mb-3">
//             <label class="form-label w-100">
//                 Monto Fiat: <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Monto Fiat"
//                 />
//             </label>
//             <select class="form-select form-select-lg mb-3">
//                 <option selected>Divisa Fiat</option>
//                 <option value="1">One</option>
//                 <option value="2">Two</option>
//                 <option value="3">Three</option>
//             </select>
//         </div>
//     </form>
// </div> -->
